div{
  border: none;
  padding: 0;
  margin: 0;
}

body{
  background-color: #1D1D21;
  color: white;
  border: none;
  padding: 0;
  margin: 0;
}

html, body { height: 100%; }

#root{
  height: 100%;
}

a{
  color: #00ffff;
  position: inherit;
  transition-duration: 0.3s;
}

a:hover{
  color: white;
}